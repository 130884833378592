import React from "react"

import { useAppSelector } from "../../../redux/hooks"
import parse from "html-react-parser"
import * as S from "../ConfirmationPage.styles"

import {
  SkeletonImage,
  SkeletonContainer,
  SkeletonText,
} from "../../../components/commons/Skeleton"
import { Wrapper } from "../../../styles/global"
import {
  selectConfirmation,
  selectIsLoadingConfirmation,
} from "../../../redux/confirmationSlice"

const ConfirmationPageTemplate: React.FC = () => {
  const confirmation = useAppSelector(selectConfirmation)
  const isLoading = useAppSelector(selectIsLoadingConfirmation)

  if (isLoading && !confirmation) {
    return (
      <Wrapper maxWidth="100%">
        <div className="d-flex align-self-center p-4">
          <SkeletonImage width="200px" height="150px" />
        </div>
        <SkeletonContainer>
          <div className="mb-3">
            <SkeletonText width="75%" />
          </div>
          <SkeletonText width="100%" />
          <SkeletonText width="100%" />
          <SkeletonText width="100%" />
          <SkeletonText width="100%" />
        </SkeletonContainer>
        <div className="mt-3 w-100">
          <S.ContentContainer>
            <SkeletonImage width="200px" height="200px" />

            <SkeletonImage width="200px" height="200px" />

            <SkeletonImage width="200px" height="200px" />

            <SkeletonImage width="200px" height="200px" />

            <SkeletonImage width="200px" height="200px" />

            <SkeletonImage width="200px" height="200px" />
          </S.ContentContainer>
        </div>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {confirmation?.image_url ? (
        <div className="d-flex align-self-center p-4 mb-3">
          <img
            alt="Brand logo"
            style={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
              maxWidth: "200px",
              maxHeight: "200px",
            }}
            src={confirmation?.image_url}
          />
        </div>
      ) : null}

      {/* <p style={{ fontSize: "1rem", whiteSpace: "pre-line" }}> */}
      {confirmation?.intro && parse(confirmation?.intro)}
      {/* </p> */}
    </Wrapper>
  )
}

export default ConfirmationPageTemplate
