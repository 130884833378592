import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Container, Row, Col } from "react-bootstrap"
import * as S from "./ConfirmationPage.styles"
import { useAppDispatch } from "../../redux/hooks"
import Footer from "../../components/commons/Footer"
import { fetchConfirmation } from "../../services/ConfirmationService"
import ConfirmationPageTemplate from "./components/ConfirmationPageTemplate"
import {
  setConfirmation,
  setIsLoadingConfirmation,
} from "../../redux/confirmationSlice"
import { Confirmation } from "../../types/Confirmation"
import ConfirmationAssets from "./components/ConfirmationAssets"
import ConfirmationValues from "./components/ConfirmationValues"

const ConfirmationPage: React.FC = () => {
  const { confirmationId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (confirmationId) {
      fetchConfirmation(confirmationId)
        .then(res => {
          dispatch(setConfirmation({ ...res } as Confirmation))
          dispatch(setIsLoadingConfirmation(false))
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [confirmationId])

  return (
    <S.Styles>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={8}>
            <ConfirmationPageTemplate />
          </Col>
        </Row>
        <ConfirmationValues />
        <Row className="justify-content-md-center">
          <Col md={8}>
            <hr className="solid" />
            <h2>Uploaded media</h2>

            <ConfirmationAssets />
          </Col>
        </Row>

        <Footer />
      </Container>
    </S.Styles>
  )
}

export default ConfirmationPage
